import React from "react";
import "./IconColumn.scss";
import { Container, Row, Col } from "react-bootstrap";

interface Item {
  icon: string;
  title: string;
  sub?: string;
}

interface IProps {
  header: string;
  sub?: string;
  items: Item[];
}

const IconColumn: React.FC<IProps> = ({ header, items, sub }) => {
  return (
    <div className="iconColumnContainer">
      <Container>
        <h1 className="h1 text-center pr-md-5 pl-md-5">{header}</h1>
        {sub && <p className="text-center mt-3">{sub}</p>}
        <div className="spacer-sm"></div>
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              key={index}
              md={3}
              className="item d-flex flex-column align-items-center"
            >
              <div className="circle d-flex align-items-center justify-content-center">
                <img src={item.icon} alt={item.title} />
              </div>
              <h4 className="text-center font-weight-bold">{item.title}</h4>
              {item.sub && <p className="text-center">{item.sub}</p>}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default IconColumn;
