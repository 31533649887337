import React from "react";
import "./Timeline.scss";
import { Container } from "react-bootstrap";

const Timeline: React.FC = () => {
  return (
    <div className="commonContainer">
      <Container>
        <h1 className="h1 text-center">HelloAR Timeline</h1>
        <div className="spacer-sm"></div>
        <img
          src="../../../../images/helloar-journey.jpg"
          alt="timeline"
          className="w-100"
        />
      </Container>
    </div>
  );
};

export default Timeline;
