import * as React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import MainLayout from "../components/MainLayout";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/about-us.scss";
import CommonSection from "../components/CommonSection";
import Timeline from "../sections/about-us/Timeline";
import IconColumn from "../components/IconColumn/IconColumn";
import { BsArrowRight } from "react-icons/bs";

const AboutUs = ({ data }) => {
  let ethosItems = [
    {
      title: "Make technology accessible",
      icon: "./icons/advantage/web.svg",
    },
    {
      title: "Simple & easy to use",
      icon: "./icons/advantage/code.svg",
    },
    {
      title: "Scalable solutions",
      icon: "./icons/advantage/scale.svg",
    },
    {
      title: "Create value",
      icon: "./icons/advantage/atom.svg",
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>HelloAR: AR VR company| AR Solutions| VR Solutions</title>
        <meta
          name="description"
          content="Known among the best AR VR companies, HelloAR is your one stop solution. We create & manage end to end visual 3D catalog of your products."
        />
        <meta
          name="keywords"
          content="ar vr company, ar solutions, vr solutions"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta
          property="og:image"
          content="../../videos/thumbnail/configurator.jpg"
        />
      </Helmet>
      <MainLayout width={80}>
        <Container fluid className="about bg-white pointer-events-all p-4">
          <div className="about text-left p-5">
            <h1 className="text-center h1">
              Driven by technology, passion, and future
            </h1>
            <p className="p">
              Humanity has always seen the world in three dimensions.
            </p>
            <p>
              Since the earliest days of our species in the Stone Age, we have
              interacted with our surroundings using 3D. This perception is
              deeply ingrained in who we are, shaping our understanding and
              interaction with reality.
            </p>
            <p>
              As we explore the pages of history, we witness the incredible
              evolution of our ability to manipulate and engage with the
              real-world. From simple tools crafted from stone to the intricate
              algorithms that power our advanced computers, we have harnessed
              the power of 3D thinking to shape the course of our civilization.
              Today, we find ourselves on the brink of a new frontier where
              technology allows us to create lifelike human holograms, blurring
              the lines between the physical and the digital.
            </p>
            <p>
              The future of humanity is undeniably intertwined with our capacity
              to simulate, recreate, and interact with three-dimensional
              environments opens up endless possibilities. It grants us the
              opportunity to explore unexplored territories, comprehend complex
              systems, and even delve into the depths of our imagination in ways
              we couldn't have imagined before. In the future, the boundaries of
              the physical world become more fluid, and the potential for
              innovation knows no limits. It exemplifies our innate curiosity
              and our ability to transform the way we perceive and shape our
              reality, propelling us towards an exhilarating and uncharted
              dimension of human existence.
            </p>
            <p>We aim to bring humanity closer to its true reality.</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              // width: "100%",
              flexWrap:"wrap"
            }}
          >
            <div
            style={{

              width:"500px",
            }}
            >
              <Image
                src="/images/about/old-computers.jpg"
                alt="images-about"
                fluid
                style={{
                  height:"300px"
                }}
              />
            </div>
            <div 
            style={{

              width:"500px",
            }}
            >
              <Image
                src="/images/about/future-ar.jpg"
                alt="images-about"
                fluid

              style={{
                height:"300px"
              }}
              />
            </div>
          </div>



          <div className="spacer-sm"></div>

          <CommonSection
            title="Our Story"
            sub="We’re a company that strongly believes that AR/VR technologies will shape the future of marketing & sales. And, hence, we’re on a mission to build an extremely simple solution that can be deployed by businesses in a matter of seconds. Today, we have many leading and respected brands as our clientele because we believe in creating value. All of this has been achieved with exemplary work and zero marketing spend. And, we know that this is just the beginning."
          >
            <Image src="/images/about/team-helloar.jpg" fluid />
          </CommonSection>

          <div className="spacer"></div>
          <Timeline />

          <div className="spacer"></div>
          <IconColumn header="Our Ethos" items={ethosItems} />
          <div className="spacer"></div>
          <Container>
            <h1 className="h1 text-center mb-5">Our technology</h1>
            <p className="text-center text-md-left">
              Our proprietary SaaS products are built to help you scale your
              business. We have created a solution that’s flexible and easy to
              adopt. Our no-code platform allows users to create product
              interactions in a matter of seconds. To accommodate the need of
              every size, our software can be implemented via iframes, through
              API, or with SDK to allow for easy customization and
              personalization on the go.
            </p>
            <p>
              <a href="/technology" className="text-green">
                Read more <BsArrowRight className="ml-2" />
              </a>
            </p>
          </Container>

          <div className="spacer"></div>
        </Container>
      </MainLayout>
    </React.Fragment>
  );
};

AboutUs.Layout = MainLayout;

export default AboutUs;
