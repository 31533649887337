import { Link } from "gatsby";
import React from "react";
import "./CommonSection.scss";
import { Container, Row, Col } from "react-bootstrap";
import { PopupButton } from "react-calendly";

interface IProps {
  title: string;
  sub: string;
  buttonText?: string;
  inverted?: boolean;
  isHomepage?: boolean;
  link?: string;
  showCta?: boolean;
}

const CommonSection: React.FC<IProps> = ({
  title,
  sub,
  buttonText,
  inverted,
  children,
  isHomepage,
  link,
  showCta,
}) => {
  return (
    <div className="commonContainer">
      <Container>
        <Row className={`${inverted ? "reverse" : ""} align-items-center`}>
          <Col lg={{ span: 6, offset: inverted ? 0 : 0 }}>
            <h1
              className={`h1 font-weight-bold mb-0 ${
                isHomepage && "home-title"
              }`}
            >
              {title}
            </h1>
            <p className="sub"> {sub}</p>
            {buttonText && buttonText.length > 0 && (
              <div className="d-flex justify-content-center justify-content-lg-start">
                {link ? (
                  <a href={link} target="_blank" rel="noreferrer">
                    <div className={`cta-demo ${showCta ? "" : "hide-cta"}`}>
                      <p className="mb-0 font-weight-bold text-light">
                        {buttonText}
                      </p>
                    </div>
                  </a>
                ) : (
                  <a
                    role="button"
                    href="/contact-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className={`cta-demo ${
                        showCta ? "" : "hide-cta"
                      } d-flex align-items-center justify-content-center`}
                    >
                      <p className="mb-0 font-weight-bold text-light">
                        {buttonText}
                      </p>
                      {/* <PopupButton
                        url="https://calendly.com/helloar/30min?primary_color=57ca85"
                        styles={{
                          padding: "1rem 1rem",
                          border: "none",
                          fontFamily: "inherit",
                          fontWeight: 700,
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                        rootElement={document.getElementById("___gatsby")}
                        text="REQUEST A DEMO"
                      /> */}
                    </div>
                  </a>
                )}
              </div>
            )}
          </Col>
          <Col
            lg={{ span: 6, offset: inverted ? 0 : 0 }}
            className="mt-5 mt-lg-0"
          >
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CommonSection;
